.loading {
    width: 100%;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: 400% 400%;
    background: var(--first-color);
     -webkit-animation: Gradient 15s ease infinite;
    -moz-animation: Gradient 15s ease infinite;
    animation: Gradient 15s ease infinite;
    overflow: hidden;
}

.loading::before, .loading::after {
    content: "";
    width: 70vmax;
    height: 70vmax;
    position: absolute;
    background: rgba(255, 255, 255, 0.07);
    left: -20vmin;
    top: -20vmin;
    animation: morph 15s linear infinite alternate, spin 20s linear infinite;
    z-index: 1;
    will-change: border-radius, transform;
    transform-origin: 55% 55%;
    pointer-events: none;
}

.loading::after {
    width: 70vmin;
    height: 70vmin;
    left: auto;
    right: -10vmin;
    top: auto;
    bottom: 0;
    animation: morph 10s linear infinite alternate, spin 26s linear infinite reverse;
    transform-origin: 20% 20%;
}

.loading .st0 {
    display: none;
}

.loading .st1 {
    display: inline;
}

.loading .st2 {
    opacity: 0.29;
}

.loading .st3 {
    fill: #FFFFFF;
}

.loading .st4 {
    clip-path: url(#SVGID_2_);
    fill: #FFFFFF;
}

.loading .st5 {
    clip-path: url(#SVGID_4_);
}

.loading .st6 {
    clip-path: url(#SVGID_6_);
}

.loading .st7 {
    clip-path: url(#SVGID_8_);
}

.loading .st8 {
    clip-path: url(#SVGID_10_);
}

.loading .st9 {
    fill: none;
}

.loading .st10 {
    clip-path: url(#SVGID_12_);
}

.loading .st11 {
    opacity: 0.7;
}

.loading .st12 {
    clip-path: url(#SVGID_14_);
}

.loading .st13 {
    opacity: 0.2;
}

.loading .st14 {
    clip-path: url(#SVGID_16_);
}

.loading .st15 {
    opacity: 0.3;
    fill: #FFFFFF;
    enable-background: new;
}

.loading .loading-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #fff;
    z-index: 100;
}